import React from "react"
import { Col, Form, Row } from "react-bootstrap"
import { useFormContext } from "react-hook-form"
import Select from "react-select"
import {
	AddressSchemaType,
	useGetDoctorAddressesMutate,
} from "../../../services/CelebDoctorAddress.Service"
import { PatientSchemaType } from "../../../services/CelebPatient.Service"
import { getPatientByIdQuery } from "../../../services/CelebTreatment.Service"
import { useDoctorListQuery, useGetAllSubUsers } from "../../../services/CelebUser.Service"
import { usePatientDialogStore } from "../../../store/patientDailogStore"
import { userSessionStore } from "../../../store/userSessionStore"
import { PatientEthenicities } from "../../../utils/appConstants"
import DatePicker from "../../form/datePicker"
import { dateFormater } from "../replaceOrder-v2/PatientInfo.tab"
import PatientInputForm, { PatientOtherForm, PatientSelectForm } from "./PatientFormFields"
import MoonLoader from "react-spinners/MoonLoader"
const PatientInfoTab = () => {
	return (
		<React.Suspense
			fallback={
				<MoonLoader
					speedMultiplier={4}
					style={{
						position: "absolute",
						top: "50%",
						left: "50%",
					}}
				/>
			}
		>
			<PatientInfoImpl />
		</React.Suspense>
	)
}

const PatientInfoImpl = () => {
	const methods = useFormContext<PatientSchemaType>()
	const { mutateAsync: getDoctorAddressById } = useGetDoctorAddressesMutate()
	useDoctorListQuery()
	const { setAllOffices, allOffices, doctorList, customErrors } = usePatientDialogStore()
	const sessionUser = userSessionStore( ( state: any ) => state.user )
	const { data: subUsers } = useGetAllSubUsers()
	const PatientDialog = usePatientDialogStore()
	const { data: defaultValuesEdit } = getPatientByIdQuery( PatientDialog.typesEditOpen )

	React.useEffect( () => {
		formEditPopulate()
	}, [] )

	const formEditPopulate = () => {
		methods.setValue( "selectedOffice", defaultValuesEdit.treatmentPlanDoctorAddressId )
		const dob = new Date( defaultValuesEdit.patient.dob )
		const setDate: HTMLInputElement | null = document.getElementById( "date" ) as HTMLInputElement
		if ( setDate ) {
			setDate.value = dateFormater( dob )
		}
		getDoctorAddress( { label: "", value: sessionUser.id } )
	}

	const getDoctorAddress = async ( e: { label: string; value: string } ) => {
		const data = await getDoctorAddressById( e.value )
		methods.setValue( "assignedDoctor", e.value )
		methods.setValue( "doctorsWithAccess", [ e.value ] )
		setAllOffices( data )
	}

	const handleVisibilityChange = ( selectedOptions: any ) => {
		const dataSet = methods.watch( "doctorsWithAccess" )
		const selectedDoctor = selectedOptions.map( ( x: any ) => x.value )
		if ( dataSet != undefined ) {
			methods.setValue( "treatmentPlan.doctorsWithAccess", [ ...dataSet, ...selectedDoctor ] )
		} else {
			methods.setValue( "treatmentPlan.doctorsWithAccess", [ ...selectedDoctor ] )
		}
	}

	return (
		<React.Fragment>
			<Form onSubmit={methods.handleSubmit( ( x ) => console.warn( x ) )}>
				<Col>
					<Row className="mt-4">
						<Col>
							<PatientInputForm name="firstName" required label="First Name" />
						</Col>
						<Col>
							<PatientInputForm name="lastName" required label="Last Name" />
						</Col>
						<Col>
							<PatientInputForm name="phone" label="Phone Number" type="number" />
						</Col>
					</Row>

					<Row className="mt-4">
						<Col>
							<PatientSelectForm name="gender" required label="Gender">
								<option value={""}>Select Gender</option>
								<option value={"male"}>Male</option>
								<option value={"female"}>Female</option>
								<option value={"other"}>Other</option>
							</PatientSelectForm>
						</Col>
						<Col>
							<DatePicker label="Date of birth" methods={methods} name="dob" />
						</Col>
						<Col>
							<PatientSelectForm name="ethenicity" label="Ethnicity">
								<option disabled>Select Ethnicity</option>
								{Object.keys( PatientEthenicities ).map( ( ethenicity ) => {
									return (
										<option key={ethenicity} value={ethenicity}>
											{PatientEthenicities[ ethenicity ]}
										</option>
									)
								} )}
							</PatientSelectForm>
						</Col>
					</Row>
					<div className="d-flex w-100 gap-4">
						<div className="w-100">
							<PatientInputForm name="email" label="Email id" type="email" />
							<p className="text-danger">{customErrors.email}</p>
						</div>

						{sessionUser.isAdmin && (
							<div className="w-100">
								<PatientOtherForm name="assignedDoctor" label="Doctor to Assign" required>
									<Select
										onChange={( e: any ) => {
											getDoctorAddress( e )
										}}
										options={doctorList}
									/>
								</PatientOtherForm>
							</div>
						)}

						<div className="w-100">
							<PatientSelectForm name="selectedOffice" required label="Select Office">
								<option value={defaultValuesEdit.treatmentPlanDoctorAddressId}>
									{defaultValuesEdit.treatmentPlanDoctorAddress.name}
								</option>
								{allOffices
									.filter( ( office ) => office.id !== defaultValuesEdit.treatmentPlanDoctorAddressId )
									.map( ( office: AddressSchemaType ) => {
										return <option value={office.id}>{office.name}</option>
									} )}
							</PatientSelectForm>
						</div>
					</div>

					{subUsers.data.length >= 1 && (
						<div className="w-50">
							<Form.Group>
								<Form.Label>Visibility</Form.Label>
								<Select
									isMulti
									options={subUsers?.data?.map( ( subUser: any ) => ( {
										value: subUser.id,
										label: `${subUser.firstName} ${subUser.lastName}`,
									} ) )}
									onChange={handleVisibilityChange}
									value={subUsers?.data?.map( ( subUser: any ) => {
										if (
											methods
												.watch( "treatmentPlan.doctorsWithAccess" )
												?.find( ( z: any ) => z == subUser.id )
										) {
											return {
												value: subUser.id,
												label: `${subUser.firstName} ${subUser.lastName}`,
											}
										}
									} )}
									isSearchable
									isClearable
								/>
							</Form.Group>
						</div>
					)}
				</Col>
			</Form>
		</React.Fragment>
	)
}

export default PatientInfoTab
