import { faCircleDown } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome"
import "@xyflow/react/dist/style.css"
import Moment from "react-moment"
import { useSearchParams } from "react-router-dom"
import {
	TimelineResponseType,
	useFetchTimeline,
	useFetchTimelineTodo,
} from "../../services/celebApitTimeline"
import { useSidebarStore } from "../../store/sideBarStore"
import { downloadFile } from "../../utils/utilFunctions"
import "./graph.css"

const CustomerTimeline = () => {
	const sidebar = useSidebarStore()
	const patientCreatetionDate = sidebar.sideBarPatientData.PatientData.createdAt ?? "null"
	const entityId = sidebar.sideBarPatientData?.PatientData?.patient?.entityId ?? ""
	const [ queryParam ] = useSearchParams()
	const treatmentid = queryParam.get( "PatientId" )
	const { data: dataTimeline } = useFetchTimeline( treatmentid ? treatmentid : "" )
	const { data: dataTimelineTodo } = useFetchTimelineTodo( treatmentid ? treatmentid : "" )
	return (
		<div
			style={{ backgroundColor: "#d9d9d9", width: "100%", padding: "1rem", borderRadius: "11px" }}
		>
			<div className="timeline" style={{ width: "100%", overflow: "auto", height: "96%" }}>
				<div className={`tl-item active`}>
					<div className={`tl-dot b-warning`}></div>
					<span className="w-100">
						<div className="d-flex justify-content-between w-100">
							<header>
								<header>Patient submitted</header>
								<br />
							</header>
							<header style={{ color: "#818181", fontSize: "14px", whiteSpace: "nowrap" }}>
								<Moment format="MM/DD/YYYY hh:mm a" local>
									{patientCreatetionDate}
								</Moment>
							</header>
						</div>
					</span>
				</div>

				{dataTimeline.map( ( x, index ) => {
					return (
						<>
							<div className={`tl-item ${x.id ? "active" : ""}`} key={x.id}>
								<div className={`tl-dot ${x.taskId ? " b-warning" : "b-danger"}`}></div>
								<span className="w-100">
									<div className="d-flex justify-content-between w-100">
										<div className="d-flex flex-column w-100">
											<header>
												{findTaskandIncreament( dataTimeline, x.taskId, index )} {x?.customMessage}
											</header>
											<br />
											<div className="d-flex justify-content-between">
												<p style={{ color: "#818181", fontSize: "14px" }}>
													{x?.timelineActionedBy?.firstName + " " + x?.timelineActionedBy?.lastName}
													<br />
													<b>{x?.action == "task_completed" ? "" : x?.action}</b>
												</p>
												<p style={{ color: "#818181", fontSize: "14px", whiteSpace: "nowrap" }}>
													<Moment format="MM/DD/YYYY hh:mma" local>
														{x?.createdAt}
													</Moment>
												</p>
											</div>
										</div>
									</div>

									<div id="d-flex">
										{x?.fileDetails?.file && (
											<div
												className="file-item my-2 p-2 border rounded d-flex align-items-center"
												onClick={async () => {
													sidebar.addTimelineDownloadingFile( index )
													const isDone = await downloadFile(
														x.fileDetails.file,
														x.fileDetails?.name,
														entityId,
													)
													console.log( "x.fileDetails", x.fileDetails )
													isDone && sidebar.removeTimelineDownloadingFile( index )
												}}
											>
												<span className="icon-container">
													{sidebar.downloadingTimelineFileIndexes.includes( index ) ? (
														<div className="spinner-border text-secondary" role="status">
															<span className="sr-only" />
														</div>
													) : (
														<FA
															icon={faCircleDown}
															size="xl"
															className="mx-2 text-end"
															style={{ fontSize: "35px", color: "#8faadc", cursor: "pointer" }}
														/>
													)}
												</span>
												<span className="flex-grow-1">
													<div className="file-name">
														{x.fileDetails?.name ?? x.fileDetails?.name}
													</div>
												</span>
											</div>
										)}
									</div>
								</span>
							</div>
						</>
					)
				} )}

				{dataTimelineTodo.map( ( x, index ) => {
					return (
						<div className={`tl-item ${x.id ? "" : ""}`}>
							<div className={`tl-dot ${index == 0 ? " b-current" : ""}`}></div>
							<div>
								<header style={{ color: "#818181", fontSize: "14px" }}>
									{x.treatmentPlanServiceTask.approvalMessage}
								</header>
							</div>
						</div>
					)
				} )}
			</div>
		</div>
	)
}

export const findTaskandIncreament = (
	dataTimeline: TimelineResponseType[],
	taskId: string,
	currenTravesringIndex: number,
) => {
	const apperedTask = dataTimeline.filter( ( x ) => x.taskId === taskId )
	const timeline = dataTimeline.slice( 0, currenTravesringIndex )
	const task = timeline.filter( ( x ) => x.taskId === taskId )
	return apperedTask.length > 1 ? `#${task.length + 1}` : ""
}

export default CustomerTimeline
