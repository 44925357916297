import { useSuspenseQuery } from "@tanstack/react-query"
import axios, { AxiosResponse } from "axios"
import { constants } from "./CelebApiConstants"

const fetchTimeline = async ( treatmentId: string ) => {
	return await axios.get<AxiosResponse<TimelineResponseType[]>>(
		`${constants.BASE_URL}/api/v1/treatmentplans/timeline/${treatmentId}`,
	)
}
const fetchConsumerTimeline = async ( treatmentId: string ) => {
	return await axios.get<AxiosResponse<TimelineResponseType[]>>(
		`${constants.BASE_URL}/api/v1/timeline/consumer?treatmentId=${treatmentId}&role=doctor`,
	)
}
const fetchTimelineTodo = async ( treatmentId: string ) => {
	return await axios.get<AxiosResponse<TreatmentTasksTimelineType[]>>(
		`${constants.BASE_URL}/api/v1/treatmenttasks/treatment/${treatmentId}?status=todo`,
	)
}

export const useFetchTimelineForConsumer = ( treatmentid: string ) => {
	return useSuspenseQuery( {
		queryKey: [],
		queryFn: () => fetchConsumerTimeline( treatmentid ).then( ( data ) => data.data.data ),
	} )
}

export const useFetchTimeline = ( treatmentid: string ) => {
	return useSuspenseQuery( {
		queryKey: [ "timeline", treatmentid ],
		queryFn: () => fetchTimeline( treatmentid ).then( ( data ) => data.data.data ),
	} )
}

export const useFetchTimelineTodo = ( treatmentid: string ) => {
	return useSuspenseQuery( {
		queryKey: [ "timelinetodo", treatmentid ],
		queryFn: () => fetchTimelineTodo( treatmentid ).then( ( data ) => data.data.data ),
	} )
}

export interface TimelineResponseType {
	id: string
	treatmentId: string
	treatmentPlanServiceTaskId: string
	customMessage: string
	fileDetails: {
		file: string
		name: string
	}
	taskId: string
	actionedBy: any
	action: string
	createdAt: string
	updatedAt: string
	timelineServiceTask: { name: string; consumerCategoryId: string; providerCategoryId: string }
	timelineActionedBy: TimelineActionedBy
	timelineTreatmentPlanServiceTask: { note: string; uploadFileUri: string; uploadFileName: string }
}

export interface TimelineActionedBy {
	firstName: any
	lastName: any
	id: any
}

export interface TreatmentTasksTimelineType {
	id: string
	note: any
	uploadFileUri: any
	checkmark: any
	providerCategoryId: string
	consumerCategoryId: string
	rollingBackToTask: any
	doctorsWithAccess: string[]
	taskStartDate: string
	taskEndDate: string
	replaceOrderTask: Boolean
	isReplaceOrder: boolean
	approvalTask: boolean
	isActive: boolean
	taskStatus: string
	taskRunId: string
	taskAssignedRole: string
	status: string
	serviceId: string
	taskId: string
	patientId: string
	doctorId: string
	treatmentId: string
	createdAt: string
	updatedAt: string
	treatmentPlanServiceTask: TreatmentPlanServiceTask
}

export interface TreatmentPlanServiceTask {
	name: string
	hasCustomMessage: boolean
	approvalMessage: string
	rejectionMessage: string
}
