import { Button } from "react-bootstrap"
import Moment from "react-moment"
import { useSearchParams } from "react-router-dom"
import { useServicePricingsfetchDetails } from "../../../services/CelebService.Service"
import { useSidebarStore } from "../../../store/sideBarStore"

const Financials = () => {
	useSidebarStore()
	const sideBarData = useSidebarStore()
	const [ searchParams ] = useSearchParams()
	const newParams = new URLSearchParams( searchParams )
	const treatmentId = newParams.get( "PatientId" )
	const { data: data } = useServicePricingsfetchDetails( treatmentId! )

	return (
		<div className="sideBarPatientInfo">
			<div className="finance-container">
				<div className="finance-row">
					<span className="finance-label">Paid:</span>
					<span className="finance-data">${data?.amount.toFixed( 2 ) ?? "N/A"}</span>
				</div>

				<div className="finance-row">
					<span className="finance-label">Approval Date:</span>
					<span className="finance-data">
						{data?.createdAt && (
							<Moment format="MM/DD/YYYY" utc>
								{data?.createdAt}
							</Moment>
						)}
					</span>
				</div>

				<div className="finance-row">
					<span className="finance-label">Receipt:</span>
					<span className="finance-data">
						<Button
							className="view-receipt-btn"
							href={
								"/receipt/" +
								treatmentId +
								"?serviceId=" +
								sideBarData?.sideBarPatientData.PatientData.serviceId +
								"&returnUrl=" +
								encodeURIComponent( window.location.href )
							}
							disabled={!data}
							size="sm"
							target="_blank"
						>
							View
						</Button>
					</span>
				</div>
			</div>
		</div>
	)
}

export default Financials
