import React from "react"
import { Modal } from "react-bootstrap"

const CloseDialog = ( {
	visible,
	callBackFunction,
}: {
	visible: boolean
	callBackFunction: ( next: string ) => void
} ) => {
	return (
		<>
			<Modal
				show={visible}
				aria-labelledby="contained-modal-title-vcenter"
				size="lg"
				backdrop="static"
			>
				<Modal.Header>
					{" "}
					<Modal.Title>Warning</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div>
						<p>Are you sure you want to close the window? You will lose the data.</p>
						<div style={{ textAlign: "end" }}>
							<button
								className="btn btn-primary mx-2"
								onClick={() => {
									callBackFunction( "minimize" )
								}}
							>
								Save and Exit
							</button>
							<button
								className="btn btn-secondary"
								onClick={() => {
									callBackFunction( "close" )
								}}
							>
								Clear and Exit
							</button>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	)
}

export default CloseDialog
