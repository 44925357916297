import React from "react"
import { CloseButton, Col, Form, Row } from "react-bootstrap"
import fileReguler from "../../../assets/img/fileRegular.svg"
import { getFileReadUrlQuery } from "../../../services/CelebMedicalFile.Service"
import { getPatientTreatmentFilesQuery } from "../../../services/CelebTreatmentFiles.Service"
import { PatientDialogState, usePatientDialogStore } from "../../../store/patientDailogStore"
import { photosLibrary } from "../editPatients-v2/defaultFileShowCase"
import { fileResult } from "./PatientPhotos.tab"
import MoonLoader from "react-spinners/MoonLoader"

const PatientScanTab = () => {
	return (
		<React.Suspense>
			<PatientScanTabImpl />
		</React.Suspense>
	)
}

const PatientScanTabImpl = () => {
	const [ selectedTab, setSelectedTab ] = React.useState<number>( 0 )

	return (
		<React.Fragment>
			<Col className="d-flex gap-5">
				<Form.Check
					type="radio"
					reverse
					label="Upload STL files"
					name="scanType"
					value={0}
					defaultChecked
					onChange={() => setSelectedTab( 0 )}
				/>
				<Form.Check
					type="radio"
					reverse
					label="Upload from scanner"
					name="scanType"
					value={1}
					onChange={() => setSelectedTab( 1 )}
				/>
			</Col>
			{selectedTabCaseHandler( selectedTab )}
		</React.Fragment>
	)
}

const selectedTabCaseHandler = ( tabId: number ) => {
	switch ( tabId ) {
		case 0:
			return <UploadStlFiles />
		case 1:
			return <UploadFromScanner />
		default:
			return <UploadStlFiles />
	}
}

export const UploadStlFiles = () => {
	const { setScan, scan, replaceOpen } = usePatientDialogStore()
	const { data: defaultTreatmentFiles } = getPatientTreatmentFilesQuery( replaceOpen )

	defaultTreatmentFiles.forEach( ( file: fileResult ) => {
		if ( file.fileCategory == "patient_scan" ) {
			const imageUri = getFileReadUrlQuery( file.fileUri )
			photosLibrary[ file.fileSubCategory ] = imageUri.data
		}
	} )

	const fileImageSaver = (
		e: React.ChangeEvent<HTMLInputElement>,
		property: keyof PatientDialogState["scan"],
	) => {
		e.preventDefault()
		if ( e.currentTarget.files ) {
			setScan( property, e.currentTarget.files[ 0 ] )
		}
	}
	return (
		<React.Fragment>
			<Form>
				<Row className="my-4">
					<Col>
						<div className="d-flex gap-4">
							<label htmlFor="file-input-upper" className="d-flex">
								<img
									src={
										scan.patient_scan_upper
											? URL.createObjectURL( scan.patient_scan_upper )
											: photosLibrary[ "patient_scan_upper" ]
									}
									className="upload-file-icon"
									onError={( { currentTarget } ) => {
										currentTarget.onerror = null
										currentTarget.src = fileReguler
									}}
								/>
								<p>
									Upper Jaw<span className="text-danger">*</span>
								</p>
							</label>
							<span className="d-flex">
								<p>{scan.patient_scan_upper ? scan.patient_scan_upper.name : ""}</p>
								<CloseButton
									className="ms-2"
									onClick={() => setScan( "patient_scan_upper", null )}
									hidden={!scan.patient_scan_upper}
								/>
							</span>
							<input
								hidden
								id="file-input-upper"
								type="file"
								onChange={( e: React.ChangeEvent<HTMLInputElement> ) =>
									fileImageSaver( e, "patient_scan_upper" )
								}
							/>
						</div>
					</Col>

					<Col>
						<div className="d-flex gap-4">
							<label htmlFor="file-input-lower" className="d-flex">
								<img
									src={
										scan.patient_scan_lower
											? URL.createObjectURL( scan.patient_scan_lower )
											: photosLibrary[ "patient_scan_lower" ]
									}
									className="upload-file-icon"
									onError={( { currentTarget } ) => {
										currentTarget.onerror = null
										currentTarget.src = fileReguler
									}}
								/>
								<p>
									Lower Jaw<span className="text-danger">*</span>
								</p>
							</label>
							<span className="d-flex">
								<p>{scan.patient_scan_lower ? scan.patient_scan_lower.name : ""}</p>
								<CloseButton
									className="ms-2"
									onClick={() => setScan( "patient_scan_lower", null )}
									hidden={!scan.patient_scan_lower}
								/>
							</span>
							<input
								hidden
								id="file-input-lower"
								type="file"
								onChange={( e: React.ChangeEvent<HTMLInputElement> ) =>
									fileImageSaver( e, "patient_scan_lower" )
								}
							/>
						</div>
					</Col>
				</Row>
			</Form>
		</React.Fragment>
	)
}

export const UploadFromScanner = () => {
	return (
		<React.Fragment>
			<Row className="mt-4">
				<div className="d-flex gap-5">
					<p>Scanner: </p>
					<Form.Check type="radio" reverse label="iTero" name="selectedscanner" value={"iTero"} />
					<Form.Check type="radio" reverse label="3Shape" name="selectedscanner" value={"3Shape"} />
					<Form.Check type="radio" reverse label="Other" name="selectedscanner" value={"Other"} />
				</div>
			</Row>
		</React.Fragment>
	)
}

export default PatientScanTab
