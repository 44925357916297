import React from "react"
import AppGraph from "../../graph/Graph"
import { PuffLoader } from "react-spinners"
import CustomerTimeline from "../../graph/CustomerGraph"
import { userSessionStore } from "../../../store/userSessionStore"

const TimeLine = () => {
	const sessionStore = userSessionStore()
	return (
		<React.Suspense
			fallback={
				<PuffLoader
					speedMultiplier={2}
					style={{ position: "absolute", top: "50%", right: "15%" }}
				/>
			}
		>
			{sessionStore.user.isDoctor ? <CustomerTimeline /> : <AppGraph />}
		</React.Suspense>
	)
}

export default TimeLine
