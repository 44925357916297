import React from "react"
import { Badge, Dropdown, DropdownButton, Table } from "react-bootstrap"
import Moment from "react-moment"
import { Params, useParams } from "react-router"
import { useSearchParams } from "react-router-dom"
import {
	PatientEditSchema,
	useTaskCompleteServicesQuery,
	useTreatmentStatusMutation,
} from "../../services/CelebTreatment.Service"
import { usePatientDialogStore } from "../../store/patientDailogStore"
import { userSessionStore } from "../../store/userSessionStore"
import { TreatmentStates } from "../../utils/appConstants"
import { CheckGenericPermission } from "../permissions/CheckPermision"
import { infoMapperNames } from "../utlity/GenricFunctions"
import SortButton from "../utlity/SortButton"
import CustomPagination from "../utlity/pagination-component"
import CompletedTableSkelton from "../../skelton-components/completedTableSkelton"

const CompletedTable = () => {
	const paramsData: Readonly<Params<string>> = useParams()
	const [ searchParams, setSearchParams ] = useSearchParams()
	const patientStore = usePatientDialogStore()
	const [ currentPage, setCurrentPage ] = React.useState<number>( 0 )
	const [ sortingData, setSortingData ] = React.useState<string[] | null>()
	const status = searchParams.get( "status" )
	const { mutateAsync: updateTaskStatus } = useTreatmentStatusMutation()
	const searchPatient = searchParams.get( "searchPatient" )
	const doctorIdParam = searchParams.get( "doctorId" )
	const { user } = userSessionStore()
	const { data: tableData } = useTaskCompleteServicesQuery( {
		search: searchPatient || "",
		limit: "10",
		offset: currentPage + "",
		serviceId: paramsData.id ? paramsData.id : "",
		status: status ? status : "",
		...( doctorIdParam && { [ "doctorId" ]: doctorIdParam } ),
		...( sortingData && {
			[ "sortingParams" ]: JSON.stringify( sortingData ),
		} ),
	} )

	const onTreatmentStateChange = async ( treatmentId: string, statusTask: string ) => {
		updateTaskStatus( { treatmentId, status: statusTask } )
	}

	const addNewQueryParam = ( patientID: string ) => {
		if ( patientID ) {
			const newParams = new URLSearchParams( searchParams )
			newParams.set( "PatientId", patientID )
			setSearchParams( newParams )
		}
	}

	const handleSortChange = ( apiParams: string[], sortOrder: "asc" | "desc" | undefined ): void => {
		const updatedSortingData = sortOrder ? [ ...apiParams, sortOrder ] : undefined
		setSortingData( updatedSortingData )
	}
	React.useEffect( () => {
		addNewQueryParam( tableData?.treatment[ 0 ]?.id )
	}, [ status, paramsData.id, currentPage, tableData?.treatment[ 0 ]?.id ] )

	return (
		<React.Suspense fallback={<CompletedTableSkelton />}>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-between",
					minHeight: "76vh",
				}}
			>
				<Table striped hover responsive={"xl"} className="text-start">
					<thead>
						<tr className="table-header" style={{ textWrap: "nowrap" }}>
							<th style={{ textAlign: "start" }}>
								Patient Name{" "}
								<SortButton sortKey="patient#firstName" onSortChange={handleSortChange} />
							</th>
							<th style={{ textAlign: "start" }}>
								Patient ID <SortButton sortKey="patient#entityId" onSortChange={handleSortChange} />
							</th>
							<th style={{ textAlign: "start" }}>
								Submit Date <SortButton sortKey="createdAt" onSortChange={handleSortChange} />
							</th>
							<th style={{ textAlign: "start" }}>
								Clinic{" "}
								<SortButton
									sortKey="treatmentPlanDoctorAddress#name"
									onSortChange={handleSortChange}
								/>
							</th>
							<th style={{ textAlign: "start" }}>
								Doctor <SortButton sortKey="doctor#firstName" onSortChange={handleSortChange} />
							</th>
							<th style={{ textAlign: "start" }}>Action Buttons</th>
						</tr>
					</thead>
					<tbody>
						{tableData ? (
							tableData.treatment.map( ( x: PatientEditSchema ) => {
								return (
									<tr
										style={{ verticalAlign: "middle" }}
										className="table-data"
										data-Active={searchParams.get( "PatientId" ) === x.id}
										onClick={() => addNewQueryParam( x.id )}
									>
										<td style={{ textAlign: "start" }}>
											<CheckGenericPermission genericLabel="Patient Name">
												{infoMapperNames( x.patient, [
													"entityId",
													"dob",
													"id",
													"gender",
													"ethenicity",
													"email",
													"phone",
												] )}{" "}
											</CheckGenericPermission>
											<Badge bg="secondary" pill hidden={!x.isReplacementOrder}>
												Replaced order
											</Badge>
										</td>
										<td style={{ textAlign: "start" }}>
											{" "}
											<CheckGenericPermission genericLabel="Patient ID">
												{x.patient.entityId}
											</CheckGenericPermission>
										</td>
										<td style={{ textAlign: "start" }}>
											<CheckGenericPermission genericLabel="Patient Submit Date">
												{<Moment date={x.createdAt} format="MM/DD/YYYY" />}
											</CheckGenericPermission>
										</td>
										<td style={{ textAlign: "start" }}>
											{" "}
											<CheckGenericPermission genericLabel="Patient Clinic">
												{x.treatmentPlanDoctorAddress.name}
											</CheckGenericPermission>
										</td>
										<td style={{ textAlign: "start" }}>
											{" "}
											<CheckGenericPermission genericLabel="Patient Assigned Doctor">
												{x.doctor.firstName + " " + x.doctor.lastName}
											</CheckGenericPermission>
										</td>
										<td style={{ textAlign: "start" }}>
											<div className="d-flex gap-2">
												<CheckGenericPermission genericLabel={"View TP"}>
													<a
														{...( x.editorFilesStatus === "pending" && user.isDoctor
															? {
																	className: "btn btn-primary disabled",
																	style: { textWrap: "nowrap", margin: "1px" },
																}
															: {
																	className: "btn btn-primary",
																	style: { textWrap: "nowrap", margin: "1px" },
																	href: "/editor/noTreatmentAction?treatmentId=" + x.id,
																	target: "_blank",
																} )}
													>
														View TP
													</a>
												</CheckGenericPermission>
												<DropdownButton id="dropdown-basic-button" title="">
													{status == "completed" && (
														<>
															<CheckGenericPermission genericLabel={"Patient Replace"}>
																<Dropdown.Item
																	href="#PatientInfo"
																	onClick={() => patientStore.setReplaceOpen( x.id )}
																>
																	Replace
																</Dropdown.Item>
															</CheckGenericPermission>
															<CheckGenericPermission genericLabel={"Patient Archive"}>
																<Dropdown.Item
																	onClick={() =>
																		onTreatmentStateChange( x.id, TreatmentStates.ARCHIVED )
																	}
																>
																	Archive
																</Dropdown.Item>
															</CheckGenericPermission>
														</>
													)}

													{status == TreatmentStates.PAUSED && (
														<>
															<Dropdown.Item
																onClick={() => onTreatmentStateChange( x.id, TreatmentStates.ACTIVE )}
															>
																Unpause
															</Dropdown.Item>
														</>
													)}

													{status == TreatmentStates.ARCHIVED && (
														<>
															<Dropdown.Item
																onClick={() =>
																	onTreatmentStateChange( x.id, TreatmentStates.COMPLETED )
																}
															>
																Unarchive
															</Dropdown.Item>
														</>
													)}
													<CheckGenericPermission genericLabel={"Patient Transfer"}>
														<Dropdown.Item>Transfer</Dropdown.Item>
													</CheckGenericPermission>
												</DropdownButton>
											</div>
										</td>
									</tr>
								)
							} )
						) : (
							<CompletedTableSkelton />
						)}
					</tbody>
				</Table>
				<div style={{ position: "sticky", bottom: 0, background: "#fff" }}>
					<CustomPagination
						currentPage={currentPage / 10}
						total={tableData?.total}
						onChange={( pagenumber ) => setCurrentPage( pagenumber * 10 )}
					/>
				</div>
			</div>
		</React.Suspense>
	)
}

export default CompletedTable
