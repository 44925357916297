import React from "react"
import { Nav, Navbar } from "react-bootstrap"
import { Link, Params, useNavigate, useParams, useSearchParams } from "react-router-dom"
import { BeatLoader } from "react-spinners"
import "react-vertical-timeline-component/style.min.css"
import "../../assets/styles/components/celebServiceTabs.css"
import "../../assets/styles/components/navbar.css"
import "../../assets/styles/pages/landing.page.css"
import { constants } from "../../services/CelebApiConstants"
import { useListAllServicesQuery } from "../../services/CelebService.Service"
import ProfileNavbar from "./ProfileNavbar"

const DashBoardNavbar = () => {
	const { data: listServices } = useListAllServicesQuery()
	const navigate = useNavigate()
	const paramsData: Readonly<Params<string>> = useParams()
	const [ searchParams ] = useSearchParams()
	const status = searchParams.get( "status" )
	const serviceLoadData = () => {
		return navigate( listServices?.services && listServices?.services[ 0 ].id )
	}
	return (
		<React.Suspense>
			<Navbar expand="lg" className="bg-body-tertiary p-0">
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
					<div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
						<Nav
							variant="tabs"
							onLoad={() => !paramsData.id && serviceLoadData()}
							style={{
								width: "-webkit-fill-available",
								height: "auto",
								border: "none",
								alignItems: "self-end",
								padding: 0,
								marginBottom: "-6px",
							}}
							activeKey={paramsData.id}
						>
							<React.Suspense>
								{listServices?.services ? (
									listServices.services.map( ( service: any ) => {
										return (
											<Nav.Link
												as={Link}
												to={`${service.id}?status=${status ?? "active"}`}
												className="border-0 text-nowrap tab-link"
												eventKey={service.id}
											>
												<img
													className="celeb-service-tab-icon"
													style={{
														borderRadius: "5px",
														objectFit: "contain",
														backgroundColor: "transparent",
													}}
													src={constants.IMAGEURL + ( service.logo ? service.logo : "building.png" )}
													onError={( { currentTarget } ) => {
														currentTarget.onerror = null //to prevent loop
														currentTarget.src = constants.IMAGEURL + "building.png"
													}}
												/>
												<span>{service.name}</span>
											</Nav.Link>
										)
									} )
								) : (
									<BeatLoader color="#9bdafd" style={{ position: "absolute", left: "30%" }} />
								)}
							</React.Suspense>
						</Nav>
						<ProfileNavbar />
					</div>
				</Navbar.Collapse>
			</Navbar>
		</React.Suspense>
	)
}

export default DashBoardNavbar
