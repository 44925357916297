import { useMutation, useSuspenseQuery } from "@tanstack/react-query"
import axios from "axios"
import React from "react"
import { fileResult } from "../components/dialogs/replaceOrder-v2/PatientPhotos.tab"
import { constants } from "./CelebApiConstants"
import { CelebRequestGeneratorService } from "./CelebRequestGenerator.Service"
export class CelebMedicalFileService {
	static _instance: CelebMedicalFileService = new CelebMedicalFileService()

	private constructor() {
		CelebMedicalFileService._instance = this
	}

	static getInstance(): CelebMedicalFileService {
		return CelebMedicalFileService._instance
	}

	async getUploadUrls( requestPayload: any[] ) {
		const requestUrl = `${constants.BASE_URL}${constants.API_V1}${constants.MEDICAL_FILES_UPLOAD_URLS}`

		return await axios.post( requestUrl, requestPayload )
	}

	async getPublicUploadUrls( requestPayload: any[] ) {
		const requestUrl = `${constants.BASE_URL}${constants.API_V1}${constants.MEDICAL_FILES_UPLOAD_URLS}/public`

		return await axios.post( requestUrl, requestPayload )
	}

	async uploadFileToS3( signedUrl: string, file: any, progresCallback?: Function ): Promise<any> {
		return await axios.put( signedUrl, file, {
			headers: {
				"Content-Type": file.type,
			},
			onUploadProgress: ( progressEvent ) => {
				const percentCompleted = Math.round(
					( progressEvent.loaded * 100 ) / ( progressEvent.total || 1 ),
				)

				progresCallback?.( percentCompleted )
			},
		} )
	}

	async getFileReadUrl( fileUri: string | null ) {
		const requestUrl = `${constants.MEDICAL_FILES}/${fileUri}`

		return await CelebRequestGeneratorService.processGetRequest( requestUrl )
	}
}

export const getFileReadUrlQuery = ( fileUri: string | null ) => {
	return useSuspenseQuery( {
		queryKey: [ "patientPhotos", fileUri ],
		queryFn: () => {
			return CelebMedicalFileService.getInstance()
				.getFileReadUrl( fileUri )
				.then( ( res ) => res.data.data )
				.then( ( res ) => res )
		},
	} )
}

export const processFileData = ( files: fileResult[] ): any | null => {
	const [ result, setResult ] = React.useState<any | null>( null )
	React.useEffect( () => {
		const loadFileUri = async () => {
			try {
				const promises = files.map( async ( fileData ) => {
					if ( fileData.fileSubCategory.includes( "replace" ) ) {
						const updatedData = await CelebMedicalFileService.getInstance().getFileReadUrl(
							fileData.fileUri,
						)
						const res_1 = {
							src: updatedData.data.data,
							alt: fileData?.fileSubCategory,
							fileCategory: fileData.fileCategory,
							fileSubCategory: fileData.fileSubCategory,
						}
						return res_1
					}
					return undefined
				} )
				const allResults = ( await Promise.all( promises ) ).filter( ( item ) => item !== undefined )
				setResult( allResults )
			} catch ( error ) {
				console.error( "Failed to load filesUri:", error )
			}
		}
		loadFileUri()
	}, [] )

	return result
}

export const getFileReadUrlMutatefn = () => {
	return useMutation( {
		mutationFn: ( fileUri: string | null ) => {
			return CelebMedicalFileService.getInstance()
				.getFileReadUrl( fileUri )
				.then( ( res ) => res.data.data )
				.then( ( res ) => res )
		},
	} )
}
