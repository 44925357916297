import { faPencil, faTrash } from "@fortawesome/free-solid-svg-icons"
import { zodResolver } from "@hookform/resolvers/zod"
import React from "react"
import { Alert, Button, Col, Form, Modal, Row, Table } from "react-bootstrap"
import { FormProvider, useFieldArray, useForm, useFormContext } from "react-hook-form"
import Select from "react-select"
import LoadingOverlay from "../../../assets/styles/components/loader/loadingOverlay"
import {
	labelFinderType,
	PreviousRollBackType,
	ReactSelectCreatableCustom,
	ServiceTaskForm,
} from "../../../services/CelebService.Service"
import { useServiceDialogStore } from "../../../store/serviceDialogStore"
import { EditTrash } from "../../utlity/EditTrash"
import {
	BoundSelect,
	ServiceFormTaskInput,
	ServiceFormType,
	ServiceTaskFormType,
} from "./serviceFunctions/ServiceFormFields"
import { TaskCategories } from "./servicePages/serviceTaskCategories"

const ServiceTaskBox = () => {
	const { serviceProviderCategories, serviceConsumerCategories } = useServiceDialogStore()
	const { control, watch, formState } = useFormContext<ServiceFormType>()
	const tasksData = useFieldArray( { name: "tasks", control, keyName: "uid" } )
	const methods = useForm<ServiceTaskFormType>( {
		resolver: zodResolver( ServiceTaskForm ),
		mode: "all",
	} )
	const [ taskOptions, setTaskOptions ] = React.useState<any[]>( [] )
	const [ taskOptionsValue, setTaskOptionsValue ] = React.useState<PreviousRollBackType>( {
		previousTasks: [],
		rollBackToTask: [],
	} )
	const [ editIndex, setEditIndex ] = React.useState<number | null>( null )

	React.useEffect( () => setTaskOptionList(), [ tasksData.fields ] )

	const validateFields = ( value: ServiceTaskFormType, indexToExclude: number | null ) => {
		const isDuplicate = tasksData.fields.some(
			( taskObject, index ) => taskObject.name === value.name && index !== indexToExclude,
		)
		if ( isDuplicate ) {
			methods.setError( "name", { message: "Please enter a unique task name" } )
			return false
		}

		return true
	}

	const addTask = React.useCallback(
		( value: ServiceTaskFormType ) => {
			if ( !validateFields( value, null ) ) {
				return
			}

			try {
				const eachTask = {
					...value,
					previousTasks: taskOptionsValue.previousTasks,
					rollBackToTask: taskOptionsValue.rollBackToTask,
					isNew: true,
					id: crypto.randomUUID(),
				}

				if ( tasksData.fields.length === 0 ) {
					eachTask.paymentStage = true
					eachTask.replaceOrderTask = true
				}

				tasksData.append( eachTask )
				cancelTaskForm()
			} catch ( error ) {
				console.error( "Add Task Error", error )
			}
		},
		[ tasksData, taskOptionsValue, validateFields ],
	)

	const checkAndDelete = ( taskObject: any, index: number ): void => {
		for ( const task of tasksData.fields ) {
			if ( task.previousTasks ) {
				for ( const priorTask of task.previousTasks ) {
					if ( priorTask.label === taskObject.name ) {
						return methods.setError( "previousTasks", {
							message: "Cannot delete task dependent on some another task",
						} )
					}
				}
			}
		}
		tasksData.remove( index )
	}

	const populateTaskToEdit = ( taskToEdit: ServiceTaskFormType, index: number ) => {
		setEditIndex( index )
		const tempObjOptionValue = {
			previousTasks: taskToEdit.previousTasks,
			rollBackToTask: taskToEdit.rollBackToTask,
		}
		setTaskOptionsValue( tempObjOptionValue )
		methods.setValue( "id", taskToEdit.id )
		methods.setValue( "name", taskToEdit.name )
		methods.setValue( "consumerCategoryId", taskToEdit.consumerCategoryId )
		methods.setValue( "providerCategoryId", taskToEdit.providerCategoryId )
		methods.setValue( "writeNote", taskToEdit.writeNote )
		methods.setValue( "uploadFile", taskToEdit.uploadFile )
		methods.setValue( "checkmark", taskToEdit.checkmark )
		methods.setValue( "hasCustomMessage", taskToEdit.hasCustomMessage )
		methods.setValue( "previousTasks", taskToEdit.previousTasks )
		methods.setValue( "rollBackToTask", taskToEdit.rollBackToTask )
		methods.setValue( "replaceOrderTask", taskToEdit.replaceOrderTask )
		methods.setValue( "paymentStage", taskToEdit.paymentStage )
		methods.setValue( "approvalMessage", taskToEdit.approvalMessage )
		methods.setValue( "rejectionMessage", taskToEdit.rejectionMessage )
		methods.setValue( "hasCustomMessage", taskToEdit.hasCustomMessage )
		methods.setValue( "approvalTask", taskToEdit.approvalTask )
		if ( taskToEdit.isNew ) {
			methods.setValue( "isNew", taskToEdit.isNew )
		}
		const updatedPriorTasks = taskOptions.filter( ( task ) => task.value !== taskToEdit.id )
		setTaskOptions( updatedPriorTasks )
	}

	const saveEditedTask = (
		event: React.MouseEvent<HTMLButtonElement>,
		value: ServiceTaskFormType,
		index: number,
	) => {
		event.preventDefault()

		if ( !validateFields( value, index ) ) {
			return
		}

		const updatedTaskData = {
			...value,
			id: value.id!,
			previousTasks: taskOptionsValue.previousTasks,
			rollBackToTask: taskOptionsValue.rollBackToTask,
		}

		tasksData.update( index, updatedTaskData )
		cancelTaskForm()
	}

	const cancelTaskForm = () => {
		setTaskOptionsValue( {
			previousTasks: [],
			rollBackToTask: [],
		} )
		setEditIndex( null )
		methods.reset()
	}

	const setTaskOptionList = () => {
		const taskOptionsList = tasksData.fields.map( ( task ) => {
			return {
				value: task.id,
				label: task.name,
			}
		} )
		setTaskOptions( taskOptionsList )
	}

	const setPaymentRollBack = (
		setPayRoll: "Payment" | "RollBack" | "approvalTask" | "hasCustomMessage",
		index: number,
	) => {
		tasksData.fields.map( ( x, taskIndex ) => {
			let updatedTask

			switch ( setPayRoll ) {
				case "Payment":
					updatedTask = { ...x, paymentStage: taskIndex === index }
					break
				case "RollBack":
					updatedTask = { ...x, replaceOrderTask: taskIndex === index }
					break
				case "approvalTask":
					updatedTask = { ...x, approvalTask: taskIndex === index }
					break
				case "hasCustomMessage":
					updatedTask = {
						...x,
						hasCustomMessage: taskIndex === index ? !x.hasCustomMessage : x.hasCustomMessage,
					}
					break
				default:
					return
			}

			tasksData.update( taskIndex, updatedTask )
		} )
	}

	const errors = watch( "taskErrors" ) || formState.errors.tasks?.message

	const serviceName = watch( "name" )

	return (
		<React.Suspense fallback={<LoadingOverlay />}>
			<Modal.Body>
				<div className="d-flex justify-content-center pb-3">
					<div style={{ fontWeight: "500", fontSize: "1.5rem" }}>{serviceName}</div>
				</div>
				<TaskCategories />
				<FormProvider {...methods}>
					<hr />
					<Form
						onSubmit={methods.handleSubmit( addTask, ( error ) => console.error( "Task Error", error ) )}
					>
						<Alert
							className="text-danger"
							hidden={!methods.formState.errors.previousTasks?.message}
						>
							{methods.formState.errors.previousTasks?.message}
						</Alert>

						{/* this row belogs to name, providerCategoryId, consumerCategoryId */}
						<Row
							className={`pt-2 ${editIndex != null ? "containerEditingTask" : ""} d-flex align-items-center`}
						>
							<Col className="">
								<ServiceFormTaskInput name="name" required label="Task Name" />
							</Col>

							<Col>
								<BoundSelect
									label="Provider Category"
									name="providerCategoryId"
									required
									options={serviceProviderCategories}
									placeholder="Select Category"
								/>
							</Col>

							<Col>
								<BoundSelect
									label="Consumer Category"
									name="consumerCategoryId"
									required
									options={serviceConsumerCategories}
									placeholder="Select Category"
								/>
							</Col>
						</Row>

						{/* this row belogs to writeNote, uploadFile, checkmark */}
						<Row
							className={`pt-2 ${editIndex != null ? "containerEditingTask" : ""} d-flex align-items-center`}
						>
							<Col>
								<Form.Check
									type="checkbox"
									className="mt-4"
									label={"Write Notes"}
									{...methods.register( "writeNote" )}
								/>
							</Col>
							<Col>
								<Form.Check
									type="checkbox"
									className="mt-4"
									label={"Upload Files"}
									{...methods.register( "uploadFile" )}
								/>
							</Col>
							<Col>
								<Form.Check
									type="checkbox"
									className="mt-4"
									label={"Checkmark"}
									{...methods.register( "checkmark" )}
								/>
							</Col>
							<Col>
								<Form.Check
									type="checkbox"
									className="mt-4"
									label={"allow message"}
									{...methods.register( "hasCustomMessage" )}
								/>
							</Col>
						</Row>

						{/* this row belogs to priorTasks  and rollBackToTask */}
						<Row className={"pt-4 pb-2 " + ( editIndex != null ? "containerEditingTask" : "" )}>
							<Col>
								<Form.Group>
									<Form.Label>Prior tasks</Form.Label>
									<Select
										options={taskOptions}
										isSearchable={true}
										isClearable={true}
										isMulti
										value={taskOptionsValue.previousTasks}
										onChange={( event ) => {
											setTaskOptionsValue( { ...taskOptionsValue, [ "previousTasks" ]: [ ...event ] } )
										}}
									/>
								</Form.Group>
							</Col>
							<Col>
								<Form.Group>
									<Form.Label>Reassignment</Form.Label>
									<Select
										options={taskOptions}
										isSearchable={true}
										isClearable={true}
										isMulti
										value={taskOptionsValue.rollBackToTask}
										onChange={( event ) => {
											setTaskOptionsValue( { ...taskOptionsValue, [ "rollBackToTask" ]: [ ...event ] } )
										}}
									/>
								</Form.Group>
							</Col>
						</Row>

						{/* this row belogs to approvalMessage and rejectionMessage */}
						<Row
							hidden={methods.watch( "hasCustomMessage" )}
							className={"pt-4 pb-2 " + ( editIndex != null ? "containerEditingTask" : "" )}
						>
							<Col>
								<Form.Group>
									<ServiceFormTaskInput name="approvalMessage" label="Timeline" />
								</Form.Group>
							</Col>
							<Col>
								<Form.Group hidden={taskOptionsValue.rollBackToTask.length <= 0}>
									<ServiceFormTaskInput name="rejectionMessage" label="Timeline Rejection" />
								</Form.Group>
							</Col>
						</Row>

						{/* this row belogs to update and cancel button */}
						<Row className="text-end d-flex justify-content-end w-100">
							{editIndex != null ? (
								<Col className="d-flex justify-content-end w-100">
									<Button
										size="sm"
										type="button"
										className="my-2 btn btn-secondary"
										onClick={( e: React.MouseEvent<HTMLButtonElement> ) =>
											saveEditedTask( e, methods.getValues(), editIndex )
										}
									>
										Update Task
									</Button>
									<div className="me-2"></div>
									<Button
										size="sm"
										variant="secondary"
										type="button"
										onClick={cancelTaskForm}
										className="my-2 btn btn-secondary"
									>
										Cancel
									</Button>
								</Col>
							) : (
								<Button size="sm" type="submit" className="my-2 w-25">
									Add Task
								</Button>
							)}
						</Row>

						{/* this row belogs to alert */}
						<Alert hidden={!errors} variant="warning">
							{errors}
						</Alert>
					</Form>

					<Table striped bordered responsive>
						<thead>
							<tr>
								<th>Task</th>
								<th className="text-center">
									Write Notes,
									<br />
									Upload Files,
									<br />
									Checkmark
								</th>
								<th className="text-center">Prior Tasks</th>
								<th className="text-center">Reassign Tasks</th>
								<th className="text-center">Provider Category</th>
								<th className="text-center">Consumer Category</th>
								<th className="text-center">Payment Task</th>
								<th className="text-center">
									Replace
									<br />
									order task
								</th>
								<th className="text-center">Approval Task</th>
								<th className="text-center">Action</th>
							</tr>
						</thead>
						{tasksData.fields.map( ( tasks, index ) => {
							return (
								<tbody key={index}>
									<tr key={index}>
										<td>{tasks.name}</td>
										<td className="text-center">
											{tasks.writeNote ? "Yes" : "No"}, {tasks.uploadFile ? "Yes" : "No"},{" "}
											{tasks.checkmark ? "Yes" : "No"}
										</td>

										<td className="text-center">
											{tasks.previousTasks &&
												tasks.previousTasks.map( ( taskData: any ) => taskData.label ).join( ", " )}
										</td>

										<td className="text-center">
											{tasks.rollBackToTask &&
												tasks.rollBackToTask.map( ( taskData: any ) => taskData.label ).join( ", " )}
										</td>

										<td className="text-center">
											{categorylabelFinder( serviceProviderCategories, tasks.providerCategoryId )}
										</td>
										<td className="text-center">
											{categorylabelFinder( serviceConsumerCategories, tasks.consumerCategoryId )}
										</td>
										<td className="text-center">
											<Form.Check
												type="radio"
												className="text-center mt-2"
												name="payment"
												checked={tasks.paymentStage}
												onChange={() => setPaymentRollBack( "Payment", index )}
											></Form.Check>
										</td>
										<td className="text-center">
											<Form.Check
												type="radio"
												name="replace"
												checked={tasks.replaceOrderTask}
												onChange={() => setPaymentRollBack( "RollBack", index )}
												className="text-center mt-2"
											/>
										</td>
										<td className="text-center">
											<Form.Check
												type="radio"
												name="approvalTask"
												checked={tasks.approvalTask}
												onChange={() => setPaymentRollBack( "approvalTask", index )}
												className="text-center mt-2"
												disabled={tasks.previousTasks.length < 1}
											/>
										</td>
										<td className="text-center">
											<EditTrash
												editIcon={{
													color: "#8faadc",
													icon: faPencil,
													style: { fontSize: "1.5rem" },
												}}
												trashIcon={{
													color: "#5c636a",
													icon: faTrash,
													style: {
														...{
															fontSize: "1.5rem",
															display: tasksData.fields.length > 1 ? "block" : "none",
														},
													},
												}}
												editCallback={() => populateTaskToEdit( tasks, index )}
												deleteCallBack={() => checkAndDelete( tasks, index )}
											/>
										</td>
									</tr>
								</tbody>
							)
						} )}
					</Table>
				</FormProvider>
			</Modal.Body>
		</React.Suspense>
	)
}

export const labelFinder = async (
	tasks: labelFinderType,
	id: string,
): Promise<string | undefined> => {
	const findArrayTask = tasks.find( ( task: any ) => task.id === id )

	if ( findArrayTask ) {
		return findArrayTask.name
	}
}

export const categorylabelFinder = ( store: ReactSelectCreatableCustom[], id: string ) => {
	const findArrayTask = store.filter( ( task: ReactSelectCreatableCustom ) => task.value == id )
	if ( findArrayTask ) {
		return findArrayTask[ 0 ]?.label
	}
}

export type serviceStoreCreateCallback = ( next: ReactSelectCreatableCustom ) => void
export type serviceStoreCallback = ( next: ReactSelectCreatableCustom[] ) => void

export default ServiceTaskBox
