import React from "react"
import { Form, FormControlProps, InputGroup } from "react-bootstrap"
export const ServicePricingSetter = ( {
	name,
	formControlAccess,
	error = false,
	errorMessage,
}: {
	name: string
	formControlAccess: FormControlProps
	error?: boolean
	errorMessage?: string
} ) => {
	return (
		<React.Fragment>
			<div>
				<span>{name}</span>
				<div className="table-cell verticalAlign-baseline">
					<InputGroup className="mb-1">
						<InputGroup.Text>$</InputGroup.Text>
						<Form.Control
							type="number"
							aria-label="Amount (to the nearest dollar)"
							isInvalid={error}
							{...formControlAccess}
						/>
					</InputGroup>
					<p hidden={!error} className="text-danger">
						{errorMessage}
					</p>
				</div>
			</div>
		</React.Fragment>
	)
}
