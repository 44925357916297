import React from "react"
import { PuffLoader } from "react-spinners"
import { useSidebarStore } from "../../../store/sideBarStore"
import SideNavbarInfo from "./asidePatientInfo"
import NavbarNavigationDummy from "./dummySidebar/navbarNavigation"
import Financials from "./financials"
import NavbarNavigation from "./navbarNavigation"
import Records from "./records"
import ReplaceOrderDetails from "./ReplaceOrderDetails"
import TimeLine from "./timeline"
import { default as SideInstructions, default as TxInstructions } from "./txInstructions"

const SideNavbar = () => {
	return (
		<React.Suspense fallback={<NavbarNavigationDummy />}>
			<main className="d-flex h-100">
				<NavbarNavigation />
				<ActiveTabExporter />
			</main>
		</React.Suspense>
	)
}

const ActiveTabExporter = () => {
	const sideBar = useSidebarStore()

	switch ( sideBar.sideBarPatientData.activeTab ) {
		case "PatientInfo":
			return (
				<React.Suspense fallback={<LoaderComponent />}>
					<SideNavbarInfo />
				</React.Suspense>
			)
		case "Timeline":
			return (
				<React.Suspense fallback={<LoaderComponent />}>
					<TimeLine />
				</React.Suspense>
			)
		case "Records":
			return (
				<React.Suspense fallback={<LoaderComponent />}>
					<Records />
				</React.Suspense>
			)
		case "Financials":
			return (
				<React.Suspense fallback={<LoaderComponent />}>
					<Financials />
				</React.Suspense>
			)
		case "TaxInstruction":
			return (
				<React.Suspense fallback={<LoaderComponent />}>
					<TxInstructions />
				</React.Suspense>
			)
		case "Order":
			return (
				<React.Suspense fallback={<LoaderComponent />}>
					<ReplaceOrderDetails />
				</React.Suspense>
			)
		default:
			return (
				<React.Suspense fallback={<LoaderComponent />}>
					<SideInstructions />
				</React.Suspense>
			)
	}
}

export const LoaderComponent = () => (
	<PuffLoader
		speedMultiplier={2}
		style={{
			position: "absolute",
			top: "50%",
			right: "15%",
		}}
	/>
)
export default SideNavbar
