import React, { useState } from "react"
import { useSearchParams } from "react-router-dom"
import Viewer from "react-viewer"
import ToothModel from "../../../assets/components/toothModel"
import { processFileData } from "../../../services/CelebMedicalFile.Service"
import { getPatientByIdQuery } from "../../../services/CelebTreatment.Service"
import { getPatientTreatmentFile } from "../../../services/CelebTreatmentFiles.Service"
import { useSidebarStore } from "../../../store/sideBarStore"
import { ImageDetailsType } from "./records"
import "./sidebar.css"

const ReplaceOrderDetails = () => {
	return (
		<React.Suspense>
			<ReplaceOrderDetailsImp />
		</React.Suspense>
	)
}

const ReplaceOrderDetailsImp = () => {
	const sideBarData = useSidebarStore()
	let initialImages: { src: string; alt?: string }[] = []
	const initialComImages: ImageDetailsType[] = []
	const [ visible, setVisible ] = useState( {
		show: false,
		key: 0,
		photos: "",
	} )
	const { data: fileData } = getPatientTreatmentFile(
		sideBarData?.sideBarPatientData?.PatientData?.patientId,
	)

	initialImages = processFileData( fileData )
	initialImages &&
		initialImages.forEach( ( file: any ) => {
			if ( file ) {
				initialComImages.push( { src: file.src ?? "", alt: file.fileCategory ?? "file" } )
			}
		} )

	const [ searchParams ] = useSearchParams()
	useSidebarStore().sideBarPatientData.PatientData
	const patientId = searchParams.get( "PatientId" )
	const { data: toothData } = getPatientByIdQuery( patientId )

	const ShowPicture = () => {
		return (
			<>
				<div style={{ backgroundColor: "#d9d9d9" }}>
					{initialComImages.map( ( image, index ) => (
						<img
							src={image.src}
							hidden={!image.src}
							className="mb-2"
							style={{ height: "100%", width: "100%", borderRadius: "10px" }}
							onClick={() => setVisible( { show: true, key: index, photos: "composite" } )}
						/>
					) )}
				</div>
			</>
		)
	}
	return (
		<div
			className="sideBarPatientInfo"
			style={{
				height: "100%",
				backgroundColor: "#d9d9d9",
				borderRadius: "10px",
				padding: "20px",
				width: "-webkit-fill-available",
				overflow: "scroll",
				scrollbarWidth: "none",
			}}
		>
			<div className="d-flex justify-content-center">Replace Order</div>
			<div>
				<span className="label">Wires :</span>{" "}
			</div>
			<span>
				<span className="sub-label">Upper Wire : </span>
				<span className="label-data-wire">
					{toothData.reaplceOrderDetails &&
						toothData.reaplceOrderDetails.upperWires.map( ( x: string ) => <text>{x}</text> )}
				</span>
			</span>
			<span>
				<span className="sub-label">Lower Wire : </span>
				<span className="label-data">
					{toothData.reaplceOrderDetails &&
						toothData.reaplceOrderDetails.lowerWires.map( ( x: string ) => <text>{x}</text> )}
				</span>
			</span>
			<div>
				<span className="label">Replace Instructions: </span>{" "}
				<span className="label-data">{toothData?.reaplceOrderDetails?.note || "N/A"}</span>
			</div>

			<div className="mt-3">
				<span className="label">Bonding Teeths :</span> <ToothModel usedFor={"teethReplacement"} />
			</div>
			<hr />
			<div>
				<ShowPicture />
			</div>
			<Viewer
				visible={visible.show}
				onClose={() => {
					setVisible( { show: false, key: 0, photos: "" } )
				}}
				images={initialImages}
				downloadable
				activeIndex={visible.key}
				showTotal
			/>
		</div>
	)
}

export default ReplaceOrderDetails
