import { create } from "zustand"
import { AddressSchemaType } from "../services/CelebDoctorAddress.Service"
import { PatientSchemaType } from "../services/CelebPatient.Service"

type EditId = string | null

export interface PatientDialogState {
	[x: string]: any
	modalOpen: boolean
	setModalOpen: ( next: boolean ) => void

	isPatientDatainProcess: boolean
	setIsPatientDatainProcess: ( next: boolean ) => void

	editOpen: PatientSchemaType | null
	setEditOpen: ( next: PatientSchemaType | null ) => void

	createOpen: boolean
	setCreateOpen: ( next: boolean ) => void

	typesEditOpen: EditId
	setTypesEditOpen: ( next: EditId ) => void

	replaceOpen: EditId
	setReplaceOpen: ( next: EditId ) => void

	paymentCreateOpen: boolean
	setPaymentCreateOpen: ( next: boolean ) => void

	activeTabSession: number | string
	setActiveTabSession: ( next: number | string ) => void

	closeModalDialog: boolean
	setCloseModalDialog: ( next: boolean ) => void

	extractionSelection: boolean
	setExtractionSelection: ( next: boolean ) => void

	xRays: {
		patient_xray_teeth: File | null
		patient_xray_skull: File | null
		[key: string]: File | null
	}
	setXRays: ( property: keyof PatientDialogState["xRays"], next: File | null ) => void

	cbctDocumentJson: {
		patient_cbct: File | null
	}
	setCbctDocument: (
		property: keyof PatientDialogState["cbctDocumentJson"],
		next: File | null,
	) => void

	individualPhoto: {
		patient_photo_face_side: File | null
		patient_photo_face_front: File | null
		patient_photo_face_front_teeth: File | null
		patient_photo_jaw_upper: File | null
		patient_photo_jaw_lower: File | null
		patient_photo_jaw_right: File | null
		patient_photo_jaw_front: File | null
		patient_photo_jaw_left: File | null
		[key: string]: File | null
	}

	replaceOrderPhoto: {
		[key: string]: File | null
	}
	patientCompositePhotoJson: {
		patient_photo_composite: File | null
	}
	setPatientReplaceOrderPhoto: (
		property: keyof PatientDialogState["replaceOrderPhoto"],
		next: File | null,
	) => void
	setPatientCompositePhotoJson: (
		property: keyof PatientDialogState["patientCompositePhotoJson"],
		next: File | null,
	) => void
	setIndividualPhoto: (
		property: keyof PatientDialogState["individualPhoto"],
		next: File | null,
	) => void

	isUploadFromScanner: {
		selectedScanner: String | null
	}
	setIsUploadFromScanner: ( selectedScanner: string | null ) => void

	scan: {
		patient_scan_upper: File | null
		patient_scan_lower: File | null
		[key: string]: File | null
	}
	setScan: ( property: keyof PatientDialogState["scan"], next: File | null ) => void
	navErrors: {
		infoTab: "red" | "none"
		scanTab: "red" | "none"
		instructions: "red" | "none"
		photos: "red" | "none"
		cbct: "red" | "none"
		replace: "red" | "none"
	}
	setNavErrors: ( next: keyof PatientDialogState["navErrors"], value: "red" | "none" ) => void

	errorToShow: {
		createPatient: string
	}
	setErrorToShow: ( message: string ) => void

	clearState: () => void

	allOffices: AddressSchemaType[]
	setAllOffices: ( next: AddressSchemaType[] ) => void
	doctorList: { value: any; label: string }[]
	setDoctorList: ( next: { value: any; label: string }[] ) => void

	customErrors: {
		email: string
	}
	setCustomError: ( key: string, message: string ) => void
}

export const usePatientDialogStore = create<PatientDialogState>()( ( set ) => ( {
	modalOpen: false,
	setModalOpen: ( next: boolean ) => set( { modalOpen: next } ),
	isPatientDatainProcess: false,
	setIsPatientDatainProcess: ( next: boolean ) => set( { isPatientDatainProcess: next } ),
	editOpen: null,
	setEditOpen: ( next: PatientSchemaType | null ) => set( { editOpen: next } ),
	createOpen: false,
	setCreateOpen: ( next: boolean ) => set( { createOpen: next } ),
	typesEditOpen: null,
	setTypesEditOpen: ( next: EditId ) => set( { typesEditOpen: next } ),
	replaceOpen: null,
	setReplaceOpen: ( next: EditId ) => set( { replaceOpen: next } ),
	paymentCreateOpen: false,
	setPaymentCreateOpen: ( next: boolean ) => set( { paymentCreateOpen: next } ),
	activeTabSession: 0,
	closeModalDialog: false,
	setCloseModalDialog: ( next: boolean ) => set( { closeModalDialog: next } ),
	setActiveTabSession: ( next ) => set( { activeTabSession: next } ),
	individualPhoto: {
		patient_photo_face_side: null,
		patient_photo_face_front: null,
		patient_photo_face_front_teeth: null,
		patient_photo_jaw_upper: null,
		patient_photo_jaw_lower: null,
		patient_photo_jaw_right: null,
		patient_photo_jaw_front: null,
		patient_photo_jaw_left: null,
	},

	extractionSelection: false,
	setExtractionSelection: ( next: boolean ) => set( { extractionSelection: next } ),

	xRays: {
		patient_xray_skull: null,
		patient_xray_teeth: null,
	},
	setXRays: ( property: keyof PatientDialogState["xRays"], next: File | null ) =>
		set( ( state ) => ( {
			xRays: {
				...state.xRays,
				[ property ]: next,
			},
		} ) ),

	setIndividualPhoto: ( property: keyof PatientDialogState["individualPhoto"], next: File | null ) =>
		set( ( state ) => ( {
			individualPhoto: {
				...state.individualPhoto,
				[ property ]: next,
			},
		} ) ),

	cbctDocumentJson: {
		patient_cbct: null,
	},
	setCbctDocument: ( property: keyof PatientDialogState["cbctDocumentJson"], next: File | null ) =>
		set( ( state ) => ( {
			cbctDocumentJson: {
				...state.cbctDocumentJson,
				[ property ]: next,
			},
		} ) ),
	patientCompositePhotoJson: {
		patient_photo_composite: null,
	},

	setPatientCompositePhotoJson: (
		property: keyof PatientDialogState["patientCompositePhotoJson"],
		next: File | null,
	) =>
		set( ( state ) => ( {
			patientCompositePhotoJson: {
				...state.patientCompositePhotoJson,
				[ property ]: next,
			},
		} ) ),

	isUploadFromScanner: {
		selectedScanner: null,
	},

	setIsUploadFromScanner: ( selectedScanner: string | null ) =>
		set( () => ( {
			isUploadFromScanner: {
				selectedScanner,
			},
		} ) ),

	scan: {
		patient_scan_upper: null,
		patient_scan_lower: null,
	},
	setScan: ( property: keyof PatientDialogState["scan"], next: File | null ) =>
		set( ( state ) => ( {
			scan: {
				...state.scan,
				[ property ]: next,
			},
		} ) ),
	replaceOrderPhoto: {
		replace_order_photo_0: null,
	},
	setPatientReplaceOrderPhoto: (
		property: keyof PatientDialogState["replaceOrderPhoto"],
		next: File | null,
	) =>
		set( ( state ) => ( {
			replaceOrderPhoto: {
				...state.replaceOrderPhoto,
				[ property ]: next,
			},
		} ) ),
	navErrors: {
		infoTab: "none",
		scanTab: "none",
		instructions: "none",
		photos: "none",
		cbct: "none",
		replace: "none",
	},
	setNavErrors: ( next: keyof PatientDialogState["navErrors"], value: "red" | "none" ) =>
		set( ( state ) => ( {
			navErrors: {
				...state.navErrors,
				[ next ]: value,
			},
		} ) ),
	errorToShow: {
		createPatient: "",
	},
	setErrorToShow: ( message: string ) =>
		set( ( state ) => ( {
			errorToShow: {
				...state.errorToShow,
				createPatient: message,
			},
		} ) ),

	clearState: () => {
		set( {
			modalOpen: false,
			editOpen: null,
			createOpen: false,
			closeModalDialog: false,
			typesEditOpen: null,
			replaceOpen: null,
			paymentCreateOpen: false,
			activeTabSession: 0,
			extractionSelection: false,
			isPatientDatainProcess: false,
			isUploadFromScanner: {
				selectedScanner: null,
			},
			individualPhoto: {
				patient_photo_face_side: null,
				patient_photo_face_front: null,
				patient_photo_face_front_teeth: null,
				patient_photo_jaw_upper: null,
				patient_photo_jaw_lower: null,
				patient_photo_jaw_right: null,
				patient_photo_jaw_front: null,
				patient_photo_jaw_left: null,
			},
			xRays: {
				patient_xray_skull: null,
				patient_xray_teeth: null,
			},
			cbctDocumentJson: {
				patient_cbct: null,
			},
			patientCompositePhotoJson: {
				patient_photo_composite: null,
			},
			scan: {
				patient_scan_upper: null,
				patient_scan_lower: null,
			},
			replaceOrderPhoto: {
				replace_order_photo_0: null,
			},
			navErrors: {
				infoTab: "none",
				scanTab: "none",
				instructions: "none",
				photos: "none",
				cbct: "none",
				replace: "none",
			},
			allOffices: [],
			customErrors: {
				email: "",
			},
		} )
		localStorage.removeItem( "patient" )
	},
	allOffices: [],
	setAllOffices: ( next: AddressSchemaType[] ) =>
		set( () => ( {
			allOffices: next,
		} ) ),
	doctorList: [],
	setDoctorList: ( next: { value: any; label: string }[] ) =>
		set( () => ( {
			doctorList: next,
		} ) ),
	customErrors: {
		email: "",
	},
	setCustomError: ( key, message ) =>
		set( ( state ) => ( {
			customErrors: { ...state.customErrors, [ key ]: message },
		} ) ),
} ) )
