import React from "react"
import "./assets/styles/components/celebServiceTabs.css"
import "./assets/styles/components/navbar.css"
import "./assets/styles/pages/landing.page.css"
import { AccountsListDialog } from "./components/dialogs/accounts-v2/AccountsList.dialog"
import PatientEditDialog from "./components/dialogs/editPatients-v2/PatientEdit.dialog"
import PatientAddDialog from "./components/dialogs/patient-v2/PatientAdd.dialog"
import ProfileDialog from "./components/dialogs/profile-v2/Profile.dialog"
import PatientReplaceDialog from "./components/dialogs/replaceOrder-v2/PatientReplace.dialog"
import ServiceListDialog from "./components/dialogs/services-v2/servicePages/ServicesList.dialog"
const TaskCompletionDialog = React.lazy(
	() => import( "./components/dialogs/tasks/TaskCompletion.dialog" ),
)

import SalesAssociatedDoctor from "./components/dialogs/sales/SalesAssociatedDoctorList"
import SalesDialog from "./components/dialogs/sales/SalesList.dialog"
import PaymentTaskDailog from "./components/dialogs/tasks/PaymentTask.dialog"
import { ToastPopup } from "./components/toast/Toast"
import ProgressBarLoader from "./components/utlity/ProgressBar.Loader"
import { usePatientDialogStore } from "./store/patientDailogStore"
import { useTaskCompletionDialogStore } from "./store/taskCompletionDialogStore"

const AppCommon = React.memo( () => {
	const patientStore = usePatientDialogStore()
	const { PaymentTaskModalOpen } = useTaskCompletionDialogStore()
	console.warn( "ReRendered" )
	return (
		<React.Suspense>
			<ServiceListDialog />
			<AccountsListDialog />
			<ProfileDialog />
			<TaskCompletionDialog />
			<SalesAssociatedDoctor />
			<SalesDialog />
			{patientStore.replaceOpen ? <PatientReplaceDialog /> : <></>}
			{patientStore.typesEditOpen ? <PatientEditDialog /> : <></>}
			<ProgressBarLoader />
			<ToastPopup />
			{PaymentTaskModalOpen && <PaymentTaskDailog />}
			{patientStore.createOpen && <PatientAddDialog />}
		</React.Suspense>
	)
} )

export default AppCommon
