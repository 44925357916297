import { faPencil, faTrash } from "@fortawesome/free-solid-svg-icons"
import React from "react"
import { Form } from "react-bootstrap"
import { useFormContext } from "react-hook-form"
import CreatableSelect from "react-select/creatable"
import { ReactSelectCreatableCustom } from "../../../../services/CelebService.Service"
import { useServiceDialogStore } from "../../../../store/serviceDialogStore"
import { TaskCategory } from "../../../../utils/appConstants"
import { EditTrash } from "../../../utlity/EditTrash"
import { CreatableSelectEdit, ServiceFormType } from "../serviceFunctions/ServiceFormFields"
import { serviceStoreCreateCallback, serviceStoreCallback } from "../ServiceTaskForm"

export const TaskCategories = React.memo( () => {
	const methods = useFormContext<ServiceFormType>()
	const serviceStore = useServiceDialogStore()

	const getCategory: { [key: string]: ReactSelectCreatableCustom[] } = {
		provide: serviceStore.serviceProviderCategories,
		consume: serviceStore.serviceConsumerCategories,
	}

	const createCategory: { [key: string]: serviceStoreCreateCallback } = {
		provide: serviceStore.setServiceProviderCategories,
		consume: serviceStore.setServiceConsumerCategories,
	}

	const updateCategory: { [key: string]: serviceStoreCallback } = {
		provide: serviceStore.updateserviceProviderCategories,
		consume: serviceStore.updateserviceConsumerCategories,
	}

	const [ editValue, setEditValue ] = React.useState<ReactSelectCreatableCustom>( {
		value: "",
		label: "",
		type: "",
		show: "",
	} )

	const createOption = (
		labelName: string,
		categoryFor: TaskCategory.PROVIDER | TaskCategory.OTHERS,
		catId?: string,
	) => {
		const id = catId ? catId : crypto.randomUUID()
		const dataToCheck = getCategory[ categoryFor ].filter(
			( x: ReactSelectCreatableCustom ) => x.label === labelName,
		)
		const dataIdToCheck = getCategory[ categoryFor ].filter(
			( x: ReactSelectCreatableCustom ) => x.value === catId,
		)
		if ( dataIdToCheck.length >= 1 ) return
		if ( dataToCheck.length >= 1 ) return

		createCategory[ categoryFor ]( {
			value: id,
			type: categoryFor,
			label: labelName,
		} )
	}

	const categoryToEdit = () => {
		const dataToEdit = getCategory[ editValue.type ].filter(
			( x: ReactSelectCreatableCustom ) => x.value != editValue.value,
		)
		updateCategory[ editValue.type ]( [ ...dataToEdit, editValue ] )
		setEditValue( { value: "", label: "", type: "" } )
	}

	const categoryToDelete = () => {
		if ( getCategory[ editValue.type ] == undefined ) return null
		const getTaskCategoryToDelete: { [key: string]: "consumerCategoryId" | "providerCategoryId" } =
			{
				provide: "providerCategoryId",
				consume: "consumerCategoryId",
			}
		const category = getTaskCategoryToDelete[ editValue.type ]
		const deleteClearance = methods.watch( "tasks" ).filter( ( x ) => x[ category ] === editValue.value )
		if ( deleteClearance.length >= 1 )
			return methods.setError( "tasks", {
				message: "Please update the task first, task is dependant on the this category",
			} )

		const dataToDelete = getCategory[ editValue.type ].filter(
			( x: ReactSelectCreatableCustom ) => x.value != editValue.value,
		)

		updateCategory[ editValue.type ]( dataToDelete )
		setEditValue( { ...editValue, value: "", label: "", type: "" } )
	}

	const editCallback = () => {
		editValue.value.length >= 2 ? setEditValue( { ...editValue, show: editValue.type } ) : null
	}

	return (
		<section style={{ width: "100%", display: "flex" }}>
			<div style={{ width: "50%" }} className="mx-4">
				<span>
					<Form.Label>Manage Provider Categories</Form.Label>
					<span className="d-flex" style={{ width: "100%" }}>
						{editValue.show === TaskCategory.PROVIDER ? (
							<div style={{ width: "100%" }}>
								<CreatableSelectEdit
									value={editValue.label}
									onChange={( e ) => {
										setEditValue( { ...editValue, label: e.currentTarget.value } )
									}}
									autoFocus
									editCallback={categoryToEdit}
									closeCallback={() => setEditValue( { value: "", label: "", type: "" } )}
								/>
							</div>
						) : (
							<section style={{ width: "100%" }} className="d-flex">
								<div style={{ width: "100%" }}>
									<CreatableSelect
										isClearable
										options={serviceStore.serviceProviderCategories}
										onCreateOption={( e ) => createOption( e, TaskCategory.PROVIDER )}
										value={
											editValue.type == TaskCategory.PROVIDER
												? editValue
												: { value: "", label: "", type: "" }
										}
										onChange={( e ) => {
											setEditValue( {
												value: e?.value + "",
												label: e?.label + "",
												type: e?.type + "",
											} )
										}}
									/>
								</div>

								<EditTrash
									editIcon={{
										color: editValue.type === TaskCategory.PROVIDER ? "#8faadc" : "#d9d4d4",
										icon: faPencil,
										style: { fontSize: "1.5rem" },
									}}
									trashIcon={{
										color: editValue.type === TaskCategory.PROVIDER ? "#5c636a" : "#d9d4d4",
										icon: faTrash,
										style: { fontSize: "1.5rem" },
									}}
									editCallback={editCallback}
									deleteCallBack={categoryToDelete}
								/>
							</section>
						)}
					</span>
				</span>
			</div>
			<span className="vr"></span>
			<div style={{ width: "50%" }} className="mx-4">
				<span>
					<Form.Label>Manage Consumer Categories</Form.Label>
					<span className="d-flex" style={{ width: "100%" }}>
						{editValue.show === TaskCategory.OTHERS ? (
							<div style={{ width: "100%" }}>
								<CreatableSelectEdit
									value={editValue.label}
									onChange={( e ) => {
										setEditValue( { ...editValue, label: e.currentTarget.value } )
									}}
									autoFocus
									editCallback={categoryToEdit}
									closeCallback={() => setEditValue( { value: "", label: "", type: "" } )}
								/>
							</div>
						) : (
							<section style={{ width: "100%" }} className="d-flex">
								<div style={{ width: "100%" }}>
									<CreatableSelect
										isClearable
										options={serviceStore.serviceConsumerCategories}
										onCreateOption={( e ) => createOption( e, TaskCategory.OTHERS )}
										value={
											editValue.type == TaskCategory.OTHERS
												? editValue
												: { value: "", label: "", type: "" }
										}
										onChange={( e ) => {
											setEditValue( {
												value: e?.value + "",
												label: e?.label + "",
												type: e?.type + "",
											} )
										}}
									/>
								</div>
								<EditTrash
									editIcon={{
										color: editValue.type === TaskCategory.OTHERS ? "#8faadc" : "#d9d4d4",
										icon: faPencil,
										style: { fontSize: "1.5rem" },
									}}
									trashIcon={{
										color: editValue.type === TaskCategory.OTHERS ? "#5c636a" : "#d9d4d4",
										icon: faTrash,
										style: { fontSize: "1.5rem" },
									}}
									editCallback={editCallback}
									deleteCallBack={categoryToDelete}
								/>
							</section>
						)}
					</span>
				</span>
			</div>
		</section>
	)
} )
