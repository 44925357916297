import React from "react"
import { Col, Row, Table } from "react-bootstrap"
import {
	DoctorPricingSchemaType,
	useListMyPricings,
} from "../../../services/CelebUserServicePricings.Service"
import { CheckGenericPermission } from "../../permissions/CheckPermision"
import MoonLoader from "react-spinners/MoonLoader"

const ProfilePricingTab = () => {
	return (
		<React.Suspense>
			<ProfilePricingTabImpl />
		</React.Suspense>
	)
}

const ProfilePricingTabImpl = () => {
	const { data } = useListMyPricings()
	const pricings: DoctorPricingSchemaType[] = data.pricings
	return (
		<React.Fragment>
			<Table striped bordered>
				<thead>
					<tr>
						<th>Service Name</th>
						<th className="text-center">Upper jaw price</th>
						<th className="text-center">Lower jaw price</th>
						<th className="text-center">Both jaw price</th>
					</tr>
				</thead>
				<tbody>
					<CheckGenericPermission genericLabel="Pricing List">
						{pricings.map( ( pricing: DoctorPricingSchemaType ) => {
							return (
								<tr>
									<td>{pricing.service.name}</td>
									<td className="text-center">${pricing.priceUpper}</td>
									<td className="text-center">${pricing.priceLower}</td>
									<td className="text-center">${pricing.priceBoth}</td>
								</tr>
							)
						} )}
					</CheckGenericPermission>
				</tbody>
			</Table>

			{pricings.length === 0 ? (
				<Row>
					<Col className="text-center">No Pricings defined yet.</Col>
				</Row>
			) : null}
		</React.Fragment>
	)
}

export default ProfilePricingTab
