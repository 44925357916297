import React from "react"
import { useSearchParams } from "react-router-dom"
import CompletedTable from "../../../../components/dashboard/CompletedTable"
import SearchTable from "../../../../components/dashboard/SearchTable"
import TasksTable from "../../../../components/dashboard/TasksTable"
import SyncLoader from "react-spinners/SyncLoader"

const landingPage = () => {
	return (
		<React.Suspense fallback={<SyncLoader />}>
			<LandingPageImpl />
		</React.Suspense>
	)
}

const LandingPageImpl = () => {
	return (
		<React.Suspense fallback={<SyncLoader />}>
			<main>
				<RenderLandingPage />
			</main>
		</React.Suspense>
	)
}
const RenderLandingPage = () => {
	const [ searchParams ] = useSearchParams()
	const status = searchParams.get( "status" )
	const searchPatient = searchParams.get( "searchPatient" )
	if ( searchPatient ) return <SearchTable></SearchTable>
	switch ( status ) {
		case "completed":
		case "paused":
		case "archived":
			return <CompletedTable />
		default:
			return <TasksTable />
	}
}

export default landingPage
